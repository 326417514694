<template>
	<div>
		<b-modal
			id="modal-update-status"
			ref="updateUserStatus"
			cancel-variant="outline-secondary"
			:ok-title="$t('Save change')"
			:cancel-title="$t('Close')"
			centered
			:title="$t('Change user status')"
			@ok="confirmUpdateStatus"
		>
			<!-- form -->
			<validation-observer ref="changeUserStatus">
				<b-form
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormStatus"
				>

					<!-- Field: Username -->
					<b-form-group
						:label="$t('Username')"
						label-for="username"
					>
						<b-form-input
							id="usernameStatus"
							v-model="userData.username"
							disabled
						/>
					</b-form-group>
					<!-- Field: Status -->
					<b-form-group
						:label="$t('Status')"
						label-for="user-status"
					>
						<v-select
							v-model="stat"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="userStatusOptions"
							:reduce="val => val.value"
							:clearable="false"
							input-id="user-status"
						/>
					</b-form-group>

					<!-- Field: Status -->
					<b-form-group>
            <label class="mb-1 d-block"><span class="text-danger">*</span>{{ $t("Note") }}</label>
						<validation-provider
							#default="{ errors }"
							name="Note"
							rules="required"
						>
							<b-form-textarea
								id="user-status-note"
								v-model="note"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:class="errors.length > 0 ? 'is-invalid':null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { userStatusOptions } from '@core/utils/const/user'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
		statusUpdate: {
			type: Boolean,
			required: true,
		},
		statusUpdateInfo: {
			type: Number,
			default: -1,
		}
	},
	data() {
		return {
			stat: this.userData.stat,
			note: ''
		}
	},
	watch: {
		userData: {
			deep: true,
			handler(newVal) {
				this.stat = newVal.stat
			}
		}
	},
	methods: {
		validationFormStatus() {
			this.$refs.changeUserStatus.validate().then(success => {
				if (success) {
					/* this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Fail! Waiting API....for message',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
					this.$nextTick(() => {
						this.$bvModal.hide('modal-update-status')
						this.resetModal()
					}) */
					const uData = {
						userId: this.userData.id,
						status: this.stat,
						note: this.note,
					}
					store.dispatch('app-user/updateUserStatus', uData)
						.then(response => {
							if (response.data.code === '00') {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Success',
										icon: 'EditIcon',
										variant: 'success',
									},
								})
								this.$nextTick(() => {
									this.$bvModal.hide('modal-update-status')
									this.resetModal()
								})
								this.$emit('update:statusUpdateInfo', this.stat)
								this.$emit('update:statusUpdate', !this.statusUpdate)
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'Error Update User Status',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		resetModal() {
			this.status = this.userData.stat
			this.note = ''
		},
		confirmUpdateStatus(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormStatus()
		}
	},
	setup() {

		return {
			userStatusOptions,
		}
	},
}
</script>

<style>

</style>
