import RoleService from '@/libs/role.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchRoles(ctx) {
			return new Promise((resolve, reject) => {
				RoleService.getRoleLists()
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchRoleLists(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				RoleService.fetchRoleLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchRoleDetail(ctx, roleId) {
			return new Promise((resolve, reject) => {
				RoleService.getRoleDetail(roleId)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchFunctions(ctx) {
			return new Promise((resolve, reject) => {
				RoleService.fetchFunctions()
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addRole(ctx, roleData) {
			return new Promise((resolve, reject) => {
				RoleService.addRole(roleData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		updateRole(ctx, roleData) {
			return new Promise((resolve, reject) => {
				RoleService.updateRole(roleData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		}
	}
}
