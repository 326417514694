/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class RoleService {

	getRoleLists() {
		return instance.get('/api/role/index', { headers: authHeader() })
				.then(
					response => {
						return response.data
					}
				)
	}

	getRoleDetail(roleId) {
		return instance.get(`/api/role/detail?roleId=${roleId}`, { headers: authHeader() })
	}

	fetchRoleLists(queryParams) {
		const pars = {
			name: queryParams.name,
			departmentId: queryParams.departmentId,
			status: queryParams.status,
			pageNumber: queryParams.page,
			pageSize: queryParams.perPage
		}
		return instance.get('/api/role/index', { headers: authHeader(), params: pars })
				.then(
					response => {
						const datas = {
							roles: response.data.data,
							total: response.data.count,
							pageSize: queryParams.page == 1 ? response.data.data.length : 25
						}
						return datas
					}
				)
	}

	fetchFunctions() {
		return instance.get('/api/manager-function/index', { headers: authHeader() })
				.then(response => response.data)
	}

	addRole(roleData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('name', roleData.name)
		data.append('departmentId', roleData.departmentId)
		data.append('active', roleData.active)
		data.append('description', roleData.description)
		roleData.functionIds.map((item, index) => {
			data.append(`functionIds[${index}]`, item)
		})
		return instance.post('/api/role/create', data, { headers: authHeader() })
	}

	updateRole(roleData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('roleId', roleData.roleId)
		data.append('name', roleData.name)
		data.append('departmentId', roleData.departmentId)
		data.append('active', roleData.active)
		data.append('description', roleData.description)
		roleData.functionIds.map((item, index) => {
			data.append(`functionIds[${index}]`, item)
		})
		return instance.post('/api/role/update', data, { headers: authHeader() })
	}
}

export default new RoleService()
