import { ref, watch, computed } from "@vue/composition-api";
import {
  userStatusOptions,
  resolveUserStatus,
  resolveUserEmailVerify,
  kycOptions
} from "@core/utils/const/user";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import i18n from "@/libs/i18n";
import { numberFormat } from "@/@core/utils/filter";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "#" },
    { key: "user", label: i18n.t("Login Name"), stickyColumn: true },
    { key: "affiliate_id", label: i18n.t("Affiliate Id") },
    { key: "status", label: i18n.t("Status") },
    { key: "name", label: i18n.t("Full Name") },
    // { key: 'currency', label: i18n.t('Currency'), class: 'text-center' },
    { key: "balance", class: "text-center" },
    {
      key: "outStandingBalance",
      label: "Outstanding Balance",
      class: "text-center",
    },
    {
      key: "sum_deposits",
      label: "Tổng nạp",
      class: "text-center",
    },
    {
      key: "winLoss",
      label: "Win/Loss",
      class: "text-center",
    },
    { key: "latest_login", label: i18n.t("Thời gian login gần nhất") },
    { key: "createdAt", label: i18n.t("Created at") },
    { key: "actions", label: i18n.t("Actions"), class: "text-center" },
  ];
  const perPage = ref(25);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const usernameFilter = ref(null);
  const emailFilter = ref(null);
  const nameFilter = ref(null);
  const phoneFilter = ref(null);
  const ipFilter = ref(null);
  const affIdFilter = ref(null);
  const fromdateFilter = ref(null);
  const todateFilter = ref(null);
  const statusFilter = ref(null);
  const isKycFilter = ref(null);
  const referralCodeFilter = ref(null);
  const affiliateIdFilter = ref(null)

  const statusUpdate = ref(false);

  let listUser = ref([]);
  const loading = ref(false)

  const refetchData = () => {
    refUserListTable.value.refresh();
    fetchUsers();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      // usernameFilter,
      // emailFilter,
      // nameFilter,
      // phoneFilter,
      // ipFilter,
      // affIdFilter,
      // fromdateFilter,
      // todateFilter,
      // statusFilter,
      // isKycFilter,
      // referralCodeFilter,
      // affiliateIdFilter,
      statusUpdate,
    ],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
    loading.value = true;
    store
      .dispatch("app-user/fetchUsers", {
        page: currentPage.value,
        username: usernameFilter.value,
        email: emailFilter.value,
        fullname: nameFilter.value,
        phone: phoneFilter.value,
        ip: ipFilter.value,
        affId: affIdFilter.value,
        from_date: fromdateFilter.value,
        to_date: todateFilter.value,
        stat: statusFilter.value,
        is_kyc: isKycFilter.value,
        referral_code: referralCodeFilter.value,
        affiliateId: affiliateIdFilter.value,
        perPage: perPage.value,
      })
      .then((response) => {
        if (response.data.code === "00") {
          // callback(response.data.data);
          listUser.value = response.data.data.map((item) => {
            let winLoss = item.sum_win / 1000 + item.sum_cancel / 1000 + item.sum_tie / 1000 - item.sum_bet / 1000;
            return {
              ...item,
              winLoss: winLoss
            }
          });
          totalUsers.value = response.data.count;
          // perPage.value =
          //   currentPage.value === 1 ? response.data.data.length : 25;
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || "Error fetching users list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }).finally(() =>{loading.value = false});
  };

store
    .dispatch("app-user/fetchUsers", {
        page: currentPage.value,
        username: usernameFilter.value,
        email: emailFilter.value,
        fullname: nameFilter.value,
        phone: phoneFilter.value,
        ip: ipFilter.value,
        affId: affIdFilter.value,
        from_date: fromdateFilter.value,
        to_date: todateFilter.value,
        stat: statusFilter.value,
        is_kyc: isKycFilter.value,
        referral_code: referralCodeFilter.value,
        affiliateId: affiliateIdFilter.value,
        perPage: perPage.value,
    })
    .then((response) => {
        if (response.data.code === "00") {
            // callback(response.data.data);
            listUser.value = response.data.data.map((item) => {
              let winLoss = item.sum_win / 1000 + item.sum_cancel / 1000 + item.sum_tie / 1000 - item.sum_bet / 1000;
              return {
                ...item,
                winLoss: winLoss
              }
            });
            totalUsers.value = response.data.count;
            // perPage.value =
            //     currentPage.value === 1 ? response.data.data.length : 25;
        } else {
            toast({
                component: ToastificationContent,
                props: {
                    title: response.data.message || "Error fetching users list",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                },
            });
        }
    });

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  return {
    fetchUsers,
    listUser,
    loading,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserStatus,
    resolveUserEmailVerify,
    refetchData,

    // Extra Filters
    usernameFilter,
    emailFilter,
    nameFilter,
    phoneFilter,
    ipFilter,
    affIdFilter,
    fromdateFilter,
    todateFilter,
    statusFilter,
    isKycFilter,
    affiliateIdFilter,
    statusUpdate,

    userStatusOptions,
    kycOptions,
    referralCodeFilter,
  };
}
