<template>
	<div>
		<b-modal
			id="modal-add-user"
			cancel-variant="outline-secondary"
			:ok-title="$t('Add')"
			:cancel-title="$t('Close')"
			size="md"
			no-enforce-focus
			ok-variant="success"
			centered
			modal-class="modal-success"
			:title="$t('Add user')"
			@ok="confirmAdd"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="addUserModal">
				<b-form method="POST" @submit.prevent="validationForm">
					<b-form-group label-for="fullName">
						<label class="mb-1">{{ $t("FullName") }}</label>
						<validation-provider
							#default="{ errors }"
							name="fullName"
							rules="required|min:4|max:50"
						>
							<b-form-input
								id="fullName"
								type="text"
								v-model="user.fullName"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group label-for="email">
						<label class="mb-1">{{ $t("Email") }}</label>
						<validation-provider
							#default="{ errors }"
							name="email"
							rules="required|email"
						>
							<b-form-input
								id="email"
								type="text"
								v-model="user.email"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group label-for="username">
						<label class="mb-1">{{ $t("User Name") }}</label>
						<validation-provider
							#default="{ errors }"
							name="username"
							rules="required|min:2|max:50"
						>
							<b-form-input
								id="username"
								type="text"
								v-model="user.username"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group label-for="phone">
						<label class="mb-1">{{ $t("Phone") }}</label>
						<validation-provider
							#default="{ errors }"
							name="phone"
							rules="required|min:9|max:12"
						>
							<b-form-input
								id="phone"
								type="number"
								v-model="user.phone"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group label-for="birthday">
						<label class="mb-1">{{ $t("Birthday") }}</label>
						<validation-provider
							#default="{ errors }"
							name="birthday"
						>
							<flat-pickr
								id="birthday"
								v-model="user.birthday"
								placeholder="YYYY-MM-DD"
								class="form-control"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<!-- <b-form-group :label="$t('Birthday')" label-for="dob">
						<flat-pickr id="dob" v-model="user.birthday" class="form-control" />
					</b-form-group> -->
					<b-form-group label-for="password">
						<label class="mb-1">{{ $t("Password") }}</label>
						<validation-provider
							#default="{ errors }"
							name="password"
							type="password"
							rules="required|min:6|max:50"
						>
							<b-form-input
								id="password"
								v-model="user.password"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<!-- <b-col cols="12" md="6"> -->
					<b-form-group :label="$t('Level')" label-for="user-level">
						<v-select
							v-model="user.levelId"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="levelLists"
							:reduce="(val) => val.id"
							label="name"
							:clearable="false"
							input-id="user-level"
						/>
					</b-form-group>
					<!-- </b-col> -->
					<b-form-group label-for="affId">
						<label class="mb-1">{{ $t("AffId") }}</label>
						<validation-provider #default="{ errors }" name="affId" type="text">
							<b-form-input
								id="affId"
								type="number"
								v-model="user.affId"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<!-- <b-form-group label-for="currency">
						<label class="mb-1">{{ $t("Currency") }}</label>
						<validation-provider
							#default="{ errors }"
							name="currency"
							type="text"
						>
							<b-form-input
								id="currency"
								type="text"
								v-model="user.currency"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group> -->
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
	BLink,
	BRow,
	BCol,
	BTabs,
	BTab,
	BForm,
	BFormGroup,
	BFormInput,
	BFormFile,
	BButton,
	BInputGroup,
	BInputGroupPrepend,
	BModal,
	VBModal,
	BInputGroupAppend,
	BFormTextarea,
	BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import Editor from "@tinymce/tinymce-vue";
import API_KEY from "@/auth/apikey/apikeyeditor";
import flatPickr from "vue-flatpickr-component";
export default {
	components: {
		BLink,
		BRow,
		BCol,
		BTabs,
		BTab,
		BForm,
		BFormGroup,
		BFormInput,
		BFormFile,
		BButton,
		BInputGroup,
		BInputGroupPrepend,
		BModal,
		BFormTextarea,
		BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
		flatPickr,
		Editor,
	},
	directives: {
		"b-modal": VBModal,
		Ripple,
	},
	data() {
		return {
			API_KEY,
			user: {
				username: "",
				email: "",
				fullName: "",
				phone: "",
				birthday: "",
				password: "",
				levelId: "",
				affId: "",
				currency: "",
			},
		};
	},
	methods: {
		validationForm() {
			this.$refs.addUserModal.validate().then((success) => {
				if (success) {
					this.addUser(this.user);
				}
			});
		},
		async addUser(userData) {
			await this.$store
				.dispatch("app-user/addUserMemberList", userData)
				.then((response) => {
					if (response.status === 200) {
						``;
						if (response.data.code == "00") {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || "success",
									icon: "CheckIcon",
									variant: "success",
								},
							});
							// this.$emit("refetch-data");
							this.$nextTick(() => {
								setTimeout(() => {
									this.$bvModal.hide("modal-add-user");
									this.resetModal();
								}, "500");
							});
							this.$emit("refetch-data");
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: "AlertTriangleIcon",
									variant: "danger",
								},
							});
						}
					}
				});
		},
		resetModal() {
			this.user = {
				fullName: "",
				email: "",
				fullName: "",
				phone: "",
				birthday: "",
				password: "",
				levelId: "",
				affId: "",
				currency: "",
			};
		},
		confirmAdd(bvModalEvt) {
			bvModalEvt.preventDefault();
			this.validationForm();
		},
	},
	setup() {
		const levelLists = ref(null)
		store.dispatch("app-user/fetchLevel").then((response) => {
			levelLists.value = response.data.data
		})
		return { levelLists }
	},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>