<template>
	<div>
		<b-modal
			id="modal-update-password"
            ref="updateUserPassword"
			cancel-variant="outline-secondary"
			:ok-title="$t('Save change')"
			:cancel-title="$t('Close')"
			centered
			:title="$t('Change password')"
			@ok="confirmUpdatePassword"
		>
			<!-- form -->
			<validation-observer ref="changeUserPassword">
				<b-form
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationForm"
				>
					<!-- Field: Username -->
					<b-form-group
						:label="$t('Username')"
						label-for="username"
					>
						<b-form-input
							id="username"
							v-model="userData.username"
							disabled
						/>
					</b-form-group>
					<!-- password -->
					<b-form-group
						:label="$t('New password')"
						label-for="reset-password-new"
					>
						<validation-provider
							#default="{ errors }"
							name="Password"
							vid="Password"
							rules="required|min:8|max:30"
						>
							<b-input-group
								class="input-group-merge"
								:class="errors.length > 0 ? 'is-invalid':null"
							>
								<b-form-input
									id="reset-password-new"
									v-model="password"
									:type="password1FieldType"
									:state="errors.length > 0 ? false:null"
									class="form-control-merge"
									name="reset-password-new"
									placeholder="············"
								/>
								<b-input-group-append is-text>
									<feather-icon
										class="cursor-pointer"
										:icon="password1ToggleIcon"
										@click="togglePassword1Visibility"
									/>
								</b-input-group-append>
							</b-input-group>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<!-- confirm password -->
					<b-form-group
						label-for="reset-password-confirm"
						:label="$t('Confirm password')"
					>
						<validation-provider
							#default="{ errors }"
							name="Confirm Password"
							rules="required|confirmed:Password"
						>
							<b-input-group
								class="input-group-merge"
								:class="errors.length > 0 ? 'is-invalid':null"
							>
								<b-form-input
									id="reset-password-confirm"
									v-model="cPassword"
									:type="password2FieldType"
									class="form-control-merge"
									:state="errors.length > 0 ? false:null"
									name="reset-password-confirm"
									placeholder="············"
								/>
								<b-input-group-append is-text>
									<feather-icon
										class="cursor-pointer"
										:icon="password2ToggleIcon"
										@click="togglePassword2Visibility"
									/>
								</b-input-group-append>
							</b-input-group>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			userId: this.userData.id,
			cPassword: '',
			password: '',
			// validation
			required,

			// Toggle Password
			password1FieldType: 'password',
			password2FieldType: 'password',
		}
	},
	computed: {
		password1ToggleIcon() {
			return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		password2ToggleIcon() {
			return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
	},
	watch: {
		password: {
			handler(newVal) {
				this.password = newVal.replace(/\s/, '')
			},
			deep: true
		},
	},
	methods: {
		togglePassword1Visibility() {
			this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
		},
		togglePassword2Visibility() {
			this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
		},
		validationForm() {
			this.$refs.changeUserPassword.validate().then(success => {
				if (success) {
					const uData = {
						userId: this.userData.id,
						password: this.password,
						note: '',
					}
					store.dispatch('app-user/changeUserPassword', uData)
						.then(response => {
							if (response.data.code == '00') {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: response.data.message || 'Success',
										icon: 'EditIcon',
										variant: 'success',
									},
								})
								this.$nextTick(() => {
									this.$bvModal.hide('modal-update-password')
									this.resetModal()
								})
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: response.data.message || 'Error',
										icon: 'AlertTriangleIcon',
										variant: 'danger',
									},
								})
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'Error Update User Password',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		resetModal() {
			this.password = ''
			this.cPassword = ''
		},
		showModalUpdatePassword() {
			this.$bvModal.show('modal-update-password')
		},
		confirmUpdatePassword(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationForm()
		}
	},
	setup() {

		return {
		}
	},
}
</script>

<style>

</style>
