var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-add-user","cancel-variant":"outline-secondary","ok-title":_vm.$t('Add'),"cancel-title":_vm.$t('Close'),"size":"md","no-enforce-focus":"","ok-variant":"success","centered":"","modal-class":"modal-success","title":_vm.$t('Add user')},on:{"ok":_vm.confirmAdd,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"addUserModal"},[_c('b-form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"fullName"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("FullName")))]),_c('validation-provider',{attrs:{"name":"fullName","rules":"required|min:4|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fullName","type":"text","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.user.fullName),callback:function ($$v) {_vm.$set(_vm.user, "fullName", $$v)},expression:"user.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"email"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Email")))]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"text","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"username"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("User Name")))]),_c('validation-provider',{attrs:{"name":"username","rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","type":"text","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"phone"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Phone")))]),_c('validation-provider',{attrs:{"name":"phone","rules":"required|min:9|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","type":"number","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"birthday"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Birthday")))]),_c('validation-provider',{attrs:{"name":"birthday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"birthday","placeholder":"YYYY-MM-DD"},model:{value:(_vm.user.birthday),callback:function ($$v) {_vm.$set(_vm.user, "birthday", $$v)},expression:"user.birthday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"password"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Password")))]),_c('validation-provider',{attrs:{"name":"password","type":"password","rules":"required|min:6|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Level'),"label-for":"user-level"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.levelLists,"reduce":function (val) { return val.id; },"label":"name","clearable":false,"input-id":"user-level"},model:{value:(_vm.user.levelId),callback:function ($$v) {_vm.$set(_vm.user, "levelId", $$v)},expression:"user.levelId"}})],1),_c('b-form-group',{attrs:{"label-for":"affId"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("AffId")))]),_c('validation-provider',{attrs:{"name":"affId","type":"text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"affId","type":"number","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.user.affId),callback:function ($$v) {_vm.$set(_vm.user, "affId", $$v)},expression:"user.affId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }