<template>
  <b-card-actions :title="$t('Filter')" action-collapse class="user-list">
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" lg="2" class="mb-2">
            <label>{{ $t("Login Name") }}</label>
            <b-form-input
              v-model="filter.username"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
            />
          </b-col>
          <b-col cols="12" md="4" lg="2" class="mb-2">
            <label>{{ $t("Name") }}</label>
            <b-form-input
              v-model="filter.name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
            />
          </b-col>
          <b-col cols="12" md="4" lg="2" class="mb-2">
            <label>{{ $t("Phone") }}</label>
            <b-form-input
              v-model="filter.phone"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
            />
          </b-col>
          <b-col cols="12" md="4" lg="2" class="mb-2">
            <label>{{ $t("Email") }}</label>
            <b-form-input
              v-model="filter.email"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
            />
          </b-col>

          <b-col cols="12" md="4" lg="2" class="mb-2">
            <label>{{ $t("Mã giới thiệu") }}</label>
            <b-form-input
              v-model="filter.referral_code"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
            />
          </b-col>

          <b-col cols="12" md="4" lg="2" class="mb-2 dateRangePicker">
            <label>{{ $t("Date") }}</label>
            <date-range-picker
              ref="picker"
              v-model="dateRange"
              :auto-apply="configDate.autoApply"
              class="w-100"
              @update="updateDates"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ filter.fromdate ? filter.fromdate : "From" }} -
                {{ filter.todate ? filter.todate : "To" }}
              </template>
            </date-range-picker>
            <b-button variant="light-success" @click="clearDate">
              <feather-icon icon="XIcon" />
            </b-button>
          </b-col>
          <b-col cols="12" md="4" lg="2" class="mb-md-0 mb-2">
            <label>{{ $t("Status") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="statusFilter"
              :options="statusOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => $emit('update:statusFilter', val)"
            />
          </b-col>
          <b-col cols="12" md="4" lg="2" class="mb-md-0 mb-2">
            <label>{{ $t("KYC") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="isKycFilter"
              :options="kycOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => $emit('update:isKycFilter', val)"
            />
          </b-col>
          <b-col cols="12" md="4" lg="2" class="mb-md-0 mb-2">
            <label>{{ $t("Mã đại lý") }}</label>
            <b-form-input
                v-model="filter.affiliate_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="w-100"
            />
          </b-col>
          <!-- action -->
          <b-col cols="12" md="6" lg="4" class="d-flex justify-content-start" style="margin-top: 23px; margin-bottom: 10px">
            <b-button variant="outline-secondary" @click="resetFilter()">
              {{ $t("Reset") }}
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              {{ $t("Search") }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormGroup,
  BFormInput,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    vSelect,
    DateRangePicker,
    BCardActions,
  },
  filters: {
    date(date) {
      return new Intl.DateTimeFormat("en-ZA").format(date);
    },
  },
  props: {
    usernameFilter: {
      type: [String, null],
      default: null,
    },
    nameFilter: {
      type: [String, null],
      default: null,
    },
    phoneFilter: {
      type: [String, null],
      default: null,
    },
    emailFilter: {
      type: [String, null],
      default: null,
    },
    ipFilter: {
      type: [String, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: null,
    },

    todateFilter: {
      type: [String, null],
      default: null,
    },
    referralCodeFilter: {
      type: [String, null],
      default: null,
    },
    affiliateIdFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },

    isKycFilter: {
      type: [Number, null],
      default: null,
    },

    statusOptions: {
      type: Array,
      required: true,
    },

    kycOptions: {
      type: Array,
      required: true,
    },

    handleSearch: {
      type: [Function, null]
    }
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);

    return {
      filter: {
        username: this.usernameFilter,
        name: this.nameFilter,
        phone: this.phoneFilter,
        ip: this.ipFilter,
        email: this.emailFilter,
        referral_code: this.referralCodeFilter,
        affiliate_id: this.affiliateIdFilter,
        fromdate: this.fromdateFilter,
        todate: this.todateFilter,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      pickerDates: {
        startDate,
        endDate,
      },
      configDate: {
        autoApply: true,
      },
    };
  },
  methods: {
    resetFilter() {
      this.$emit("update:usernameFilter", null);
      this.$emit("update:nameFilter", null);
      this.$emit("update:phoneFilter", null);
      this.$emit("update:emailFilter", null);
      this.$emit("update:ipFilter", null);
      this.$emit("update:fromdateFilter", null);
      this.$emit("update:todateFilter", null);
      this.$emit("update:statusFilter", null);
      this.$emit("update:isKycFilter", null);
      this.$emit("update:referralCodeFilter", null);
      this.$emit("update:affiliateIdFilter", null);
      this.filter = {
        username: null,
        name: null,
        phone: null,
        email: null,
        ip: null,
        fromdate: null,
        todate: null,
        referral_code: null,
      };
    },
    searchFilter() {
      this.$emit("update:usernameFilter", this.filter.username);
      this.$emit("update:phoneFilter", this.filter.phone);
      this.$emit("update:ipFilter", this.filter.ip);
      this.$emit("update:usernameFilter", this.filter.username);
      this.$emit("update:nameFilter", this.filter.name);
      this.$emit("update:emailFilter", this.filter.email);
      this.$emit("update:fromdateFilter", this.filter.fromdate);
      this.$emit("update:todateFilter", this.filter.todate);
      this.$emit("update:referralCodeFilter", this.filter.referral_code);
      this.$emit("update:affiliateIdFilter", this.filter.affiliate_id);
      this.handleSearch();
    },
    updateDates(date) {
      const data = {
        startDate: new Intl.DateTimeFormat("en-CA").format(date.startDate),
        endDate: new Intl.DateTimeFormat("en-CA").format(date.endDate),
      };
      this.filter.fromdate = data.startDate;
      this.filter.todate = data.endDate;
    },
    clearDate(event) {
      event.preventDefault();
      this.filter.fromdate = null;
      this.filter.todate = null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.dark-layout {
  .reportrange-text {
    background-color: #283046 !important;
    border-color: #404656 !important;
    color: #b4b7bd !important;
  }
}
.dateRangePicker button {
  position: absolute !important;
  right: 15px;
}

.user-list {
  .card-body {
      padding: 0.5rem;
  }

  .card .card-header {
    padding: 1rem;
  }
}
</style>
